import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Flex,
  HStack,
  Image,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { differenceInDays, format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import invert from 'invert-color';
import fp from 'lodash/fp';
import { IEventsEntry } from 'services/resources/events/types.d';

export const EventItem: React.FC<IEventsEntry> = (props): JSX.Element => {
  const {
    accentColor,
    cost,
    cover,
    date,
    description,
    id,
    videoLinks,
    media,
    title,
  } = props;

  /**
   * Function that compares if there are more than seven days from the current
   * date to the event date. If so, allow to sign-up, otherwise, avoid it.
   * @param selectedEventDate
   * @returns dateTestPass
   */
  const compareDates = (selectedEventDate: Date): boolean => {
    let dateTestPass = false;
    const parsedDate = parseISO(`${selectedEventDate}`);
    const currentDate = new Date();
    if (differenceInDays(currentDate, parsedDate) < -7) {
      dateTestPass = true;
    }
    return dateTestPass;
  };

  return (
    <VStack bg={accentColor} pb={10} w="100%">
      <Flex
        alignItems="flex-end"
        bgImage={`linear-gradient(to bottom, transparent, ${accentColor}), url(${cover})`}
        bgRepeat="no-repeat"
        bgSize="100% 500px"
        h="500px"
        justifyContent="center"
        w="100%"
      >
        <Text
          className="suomi-hand-font"
          color={accentColor === '#FFFFFF' ? 'black' : 'white'}
          fontSize={48}
          fontWeight="bold"
          pb={5}
          textAlign="center"
        >
          {title}
        </Text>
      </Flex>
      <HStack spacing="400px">
        <VStack>
          <Text
            className="suomi-hand-font"
            fontSize={40}
            fontWeight="bold"
            textAlign="center"
          >
            Fecha:
          </Text>
          <Text>
            {`${format(new Date(date), 'EEE dd MMM yyyy', {
              locale: es,
            })} \n a las ${format(new Date(date), 'HH:mm')} hrs.`}
          </Text>
        </VStack>
        <VStack>
          <Text
            className="suomi-hand-font"
            fontSize={40}
            fontWeight="bold"
            textAlign="center"
          >
            Costo:
          </Text>
          <Text textAlign="center">{`$${cost}`}</Text>
        </VStack>
      </HStack>
      <Text
        className="suomi-hand-font"
        fontSize={40}
        fontWeight="bold"
        pt={5}
        textAlign="center"
      >
        Descripción:
      </Text>
      <Text textAlign="center">{description}</Text>
      {!fp.isEmpty(media) ? (
        <Flex flexDirection="column" px="60px">
          <Text
            className="suomi-hand-font"
            fontSize={40}
            fontWeight="bold"
            pt={5}
            textAlign="center"
          >
            Medios:
          </Text>
          <HStack overflow="auto" pb={4}>
            {media?.map((img) => (
              // eslint-disable-next-line react/jsx-key
              <Image px={2} src={img} />
            ))}
          </HStack>
        </Flex>
      ) : (
        <Flex pb={4} />
      )}
      {!fp.isEmpty(videoLinks) ? (
        <Accordion allowMultiple defaultIndex={[0]}>
          <AccordionItem borderColor="transparent">
            <AccordionButton
              _expanded={{ justifyContent: 'center' }}
              _focus={{}}
              pl="55px"
            >
              <Text
                className="suomi-hand-font"
                fontSize={40}
                fontWeight="bold"
                pr={2}
                textAlign="center"
              >
                Enlaces
              </Text>
              <AccordionIcon fontSize="40px" />
            </AccordionButton>
            <AccordionPanel>
              <VStack>
                {videoLinks?.map((link) => (
                  // eslint-disable-next-line react/jsx-key
                  <Link href={link} isExternal>
                    {link}
                  </Link>
                ))}
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ) : (
        <Flex pb={4} />
      )}
      <Link
        _hover={{}}
        display={compareDates(date as Date) ? 'flex' : 'none'}
        href={`https://terappia.evoluciont.com.mx/eventSignUp/${id}`}
        isExternal
      >
        <Button
          bg={invert(accentColor)}
          color={accentColor === '#000000' ? 'black' : 'white'}
          fontSize={20}
        >
          Registrarse
        </Button>
      </Link>
    </VStack>
  );
};
