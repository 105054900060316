import { ChakraProvider } from '@chakra-ui/react';
import {
  Blog,
  BlogEntry,
  ContactUs,
  Events,
  Home,
  Privacy,
  Staff,
  Therapies,
} from 'pages';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { setUserData } from 'redux/slices/user';
import { IUserState } from 'redux/types.d';
import { logInAnonymously } from 'services/resources/resources-recipes/auth-recipes';
import { IUser } from 'services/resources/users/types.d';
import theme from 'styles/theme';

export const App: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();

  /**
   * Function that by default anonymouslt authenticates the user and stores
   * the auth data to the global state.
   */
  const handleAnonymousLogIn = async (): Promise<void> => {
    const anonymousToken = await logInAnonymously();
    const dummyUserData = {} as IUser;
    const loggedUser: IUserState = {
      ...dummyUserData,
      logged: true,
      token: anonymousToken,
      anonymous: true,
    };
    dispatch(setUserData(loggedUser as IUserState));
  };

  useEffect(() => {
    handleAnonymousLogIn();
  }, []);

  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<Therapies />} path="/therapies" />
          <Route element={<ContactUs />} path="/contact-us" />
          <Route element={<Events />} path="/events" />
          <Route element={<Staff />} path="/staff" />
          <Route element={<Privacy />} path="/privacy" />
          <Route element={<Blog />} path="/blog" />
          <Route element={<BlogEntry />} path="/blogEntry/:entryId" />
        </Routes>
      </ChakraProvider>
    </BrowserRouter>
  );
};
