import { PageContainer } from 'components/elements/PageContainer';

import { Experts } from './components/Experts';
import { Media } from './components/Media';
import { Model } from './components/Model';
import { Samurai } from './components/Samurai';
import { Slider } from './components/Slider';
import { TherapyTypes } from './components/TherapyTypes';

export const Home: React.FC = (): JSX.Element => (
  <PageContainer>
    <Slider />
    <TherapyTypes />
    <Samurai />
    <Model />
    <Media />
    <Experts />
  </PageContainer>
);
