import { Button, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { DashboardInputField } from 'components/inputs/DashboardInputField';
import { Formik } from 'formik';
import fp from 'lodash/fp';
import { IContactRequestPayload } from 'services/resources/contact-requests/types.d';
import { colors } from 'styles/colors';

import {
  contactRequestFormCopies,
  ContactRequestFormValidationSchema,
  INITIAL_VALUES,
} from './helpers/form-helpers';

interface IContactRequestFormProps {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: IContactRequestPayload) => void;
}

export const ContactRequestForm: React.FC<IContactRequestFormProps> = (
  props,
) => {
  const { onSubmit } = props;

  /**
   * Function that uses Formik's isValid and values values for determining
   * if the criteria is met to allow form submission or not.
   * @param valid
   * @param values
   * @returns button disabled status
   */
  const getDisabled = (
    valid: boolean,
    values: IContactRequestPayload,
  ): boolean => {
    if (
      !valid ||
      fp.isEmpty(values.firstName) ||
      fp.isEmpty(values.fatherName) ||
      fp.isEmpty(values.email) ||
      fp.isEmpty(values.phone) ||
      fp.isEmpty(values.contactReason)
    ) {
      return true;
    }
    return false;
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={(v, { resetForm }) => {
        onSubmit(v);
        resetForm({});
      }}
      validationSchema={ContactRequestFormValidationSchema}
    >
      {({ handleChange, handleSubmit, errors, isValid, values }) => (
        <VStack color="gray" fontSize={20} py={6} textAlign="left">
          <SimpleGrid columns={2} pb={4} spacingX={6} spacingY={4}>
            <Text>Nombre:</Text>
            <DashboardInputField
              borderColor="gray"
              error={errors.firstName}
              onChangeText={handleChange('firstName')}
              placeholder={contactRequestFormCopies.firstName}
              value={values.firstName}
            />
            <Text>Apellido(s):</Text>
            <DashboardInputField
              borderColor="gray"
              error={errors.fatherName}
              onChangeText={handleChange('fatherName')}
              placeholder={contactRequestFormCopies.fatherName}
              value={values.fatherName}
            />
            <Text>Correo electrónico:</Text>
            <DashboardInputField
              borderColor="gray"
              error={errors.email}
              onChangeText={handleChange('email')}
              placeholder={contactRequestFormCopies.email}
              value={values.email}
            />
            <Text>Teléfono:</Text>
            <DashboardInputField
              borderColor="gray"
              error={errors.phone}
              onChangeText={handleChange('phone')}
              placeholder={contactRequestFormCopies.phone}
              value={values.phone}
            />
            <Text>Asunto:</Text>
            <DashboardInputField
              borderColor="gray"
              error={errors.contactReason}
              onChangeText={handleChange('contactReason')}
              placeholder={contactRequestFormCopies.contactReason}
              value={values.contactReason}
            />
          </SimpleGrid>
          <Button
            _focus={{}}
            _hover={{ bg: colors.green[200], color: 'white' }}
            bg={colors.green[100]}
            borderRadius={1}
            color="black"
            fontSize={20}
            h={50}
            isDisabled={getDisabled(isValid, values)}
            onClick={() => handleSubmit()}
            style={{ marginLeft: 110 }}
          >
            Enviar
          </Button>
        </VStack>
      )}
    </Formik>
  );
};
