import {
  Button,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  StackProps,
} from '@chakra-ui/react';
import { StyledLink } from 'components/elements/StyledLink';
import {
  TERAPPIA_LOGO_URL,
  TERAPPIA_URL,
} from 'helpers/data-helpers/url-helpers';
import LogoWText from 'images/navbar/evolucion-terapeutica.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from 'styles/colors';

export const Navbar: React.FC<StackProps> = (props): JSX.Element => {
  const { ...rest } = props;

  const [onTop, setOnTop] = useState<boolean>(true);

  const navigate = useNavigate();

  const openInNewTab = (url: string): void => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY !== 0) {
        setOnTop(false);
      } else {
        setOnTop(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <HStack h={onTop ? undefined : '80px'} {...rest}>
      <Image fit="contain" h={onTop ? 120 : 70} mr={2} src={LogoWText} />
      <StyledLink fontSize={18} to="/">
        Inicio
      </StyledLink>
      <StyledLink fontSize={18} to="/staff">
        Psicólogos/Sexólogos
      </StyledLink>
      <StyledLink fontSize={18} to="/therapies">
        Tipos de acompañamiento
      </StyledLink>
      <Menu offset={[-190, 0]}>
        <MenuButton
          _active={{ bg: 'transparent' }}
          _focus={{ color: 'white' }}
          _hover={{ color: colors.blue[200] }}
          as={Button}
          bg="transparent"
          color={colors.blue[300]}
          fontSize={onTop ? 30 : 18}
          style={{ marginBottom: 2 }}
        >
          +
        </MenuButton>
        <MenuList bg="white">
          {/* <MenuItem _hover={{ bg: colors.gray[100] }} color="black">
            Nuestra Historia
          </MenuItem> */}
          {/* <MenuDivider bg="black" /> */}
          <MenuItem
            _hover={{ bg: colors.gray[100] }}
            bg="white"
            color="black"
            onClick={() => navigate('/events')}
          >
            Eventos, Cursos y Talleres
          </MenuItem>
          <MenuDivider bg="black" />
          {/* <MenuItem _hover={{ bg: colors.gray[100] }} color="black">
            Colaboraciones
          </MenuItem> */}
          <MenuItem
            _hover={{ bg: colors.gray[100] }}
            bg="white"
            color="black"
            onClick={() => navigate('/blog')}
          >
            Blog
          </MenuItem>
        </MenuList>
      </Menu>
      <Image
        _hover={{ cursor: 'pointer', opacity: 0.8 }}
        alignSelf="center"
        alt="terappia-logo"
        h={onTop ? 50 : 34}
        onClick={() => openInNewTab(TERAPPIA_URL)}
        src={TERAPPIA_LOGO_URL}
      />
      <Button
        _focus={{}}
        _hover={{ bg: colors.green[200], color: 'white' }}
        bg={colors.green[100]}
        borderRadius={1}
        color="black"
        fontSize={18}
        h={onTop ? 50 : 34}
        onClick={() => navigate('/contact-us')}
        px={6}
        style={{ marginLeft: 18 }}
      >
        Contáctanos
      </Button>
    </HStack>
  );
};

Navbar.defaultProps = {
  w: '100%',
  justifyContent: 'center',
  pb: 2,
  position: 'sticky',
  top: 0,
  zIndex: 1,
};
