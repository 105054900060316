import { instance } from 'services/instance';

import { IContactRequestPayload } from './types.d';

export const contactRequestsResources = {
  create: (payload: IContactRequestPayload, token: string): Promise<void> =>
    instance.post(
      '/contactRequestsAPI',
      { contactRequest: payload },
      { headers: { authorization: token } },
    ),
};
