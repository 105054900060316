import {
  Button,
  HStack,
  IconButton,
  Image,
  Link,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  FACEBOOK_URL,
  LINKEDIN_URL,
  TWITTER_URL,
  WHATSAPP_URL,
} from 'helpers/data-helpers/url-helpers';
import Logo from 'images/footer/logo-icon.png';
import {
  RiFacebookFill,
  RiHome7Fill,
  RiLinkedinFill,
  RiMailFill,
  RiMessageFill,
  RiPhoneFill,
  RiTwitterFill,
} from 'react-icons/ri';
import { Link as ReachLink } from 'react-router-dom';
import { colors } from 'styles/colors';

export const Footer: React.FC<StackProps> = (props): JSX.Element => {
  const { ...rest } = props;

  return (
    <VStack {...rest}>
      <HStack
        bg={colors.blue[900]}
        justifyContent="center"
        spacing={300}
        w="100%"
      >
        <HStack>
          <Image h={250} src={Logo} />
          <VStack>
            <Text>
              <Text fontSize={25}>Evolución Terapéutica, S.C.</Text>
              <Text fontSize={20}>Grajales Robles 16 03103</Text>
              <Text fontSize={20}>Benito Juárez, CD México </Text>
            </Text>
          </VStack>
        </HStack>
        <VStack py={8}>
          <Text fontSize={20}>Contáctate con nosotros</Text>
          <HStack>
            <RiMessageFill size={20} />
            <Text fontSize={20}>Envíanos tus dudas</Text>
          </HStack>
          <HStack>
            <RiMailFill size={20} />
            <Text fontSize={20}>info@evoluciont.com.mx</Text>
          </HStack>
          <HStack>
            <RiPhoneFill size={20} />
            <Text fontSize={20}>(55) 6840 9301</Text>
          </HStack>
          <HStack>
            <Link href={FACEBOOK_URL} isExternal>
              <IconButton
                aria-label="Search database"
                icon={<RiFacebookFill size={20} />}
                title="Facebook"
              />
            </Link>
            <Link href={TWITTER_URL} isExternal>
              <IconButton
                aria-label="Search database"
                icon={<RiTwitterFill size={20} />}
                title="Twitter"
              />
            </Link>
            <Link href={LINKEDIN_URL} isExternal>
              <IconButton
                aria-label="Search database"
                icon={<RiLinkedinFill size={20} />}
                title="LinkedIn"
              />
            </Link>
          </HStack>
          <Link href={WHATSAPP_URL} isExternal pb={4}>
            <Button
              _focus={{}}
              _hover={{ bg: colors.green[200], color: 'white' }}
              bg={colors.green[100]}
              borderRadius={30}
              color="black"
              fontSize={10}
              h={50}
              title="vía WhatsApp"
              w={220}
            >
              AGENDA TU CITA
            </Button>
          </Link>
          <Link as={ReachLink} to="/">
            <IconButton
              aria-label="Search database"
              icon={<RiHome7Fill size={20} />}
              title="Inicio"
            />
          </Link>
        </VStack>
      </HStack>
      <HStack
        bg={colors.blue[700]}
        justifyContent="center"
        minH="60px"
        spacing={350}
        w="100%"
      >
        <Text fontSize={20}>Derechos reservados © Evolución Terapéutica</Text>
        <Text fontSize={20}>Desarrollado por Red Mars, 2023.</Text>
      </HStack>
    </VStack>
  );
};

Footer.defaultProps = {
  w: '100%',
  bg: 'red',
  spacing: 0,
};
