/**
 * Function that takes an array of objects containing a date property and
 * sorts the array based on this.
 * @param array
 * @returns sorted by date array.
 */
export const sortObjectArrayByDate = (
  array: object[],
  sortDatesInverted = false,
): object[] =>
  array.sort((a: object, b: object) => {
    const dateA = new Date(a['date' as keyof typeof a]);
    const dateB = new Date(b['date' as keyof typeof b]);
    if (!sortDatesInverted) {
      return Number(dateA) - Number(dateB);
    }
    return Number(dateB) - Number(dateA);
  });

/**
 * Function that sorts data from an array when such has a created property.
 * Assumpting that such property is from FirebaseDate type.
 * @param array
 * @param sortDatesInverted
 * @returns sorted data
 */
export const sortObjectArrayByCreated = (
  array: object[],
  sortDatesInverted = false,
): object[] =>
  array.sort((a: object, b: object) => {
    const createdAObject = a['created' as keyof typeof a];
    const createdBObject = b['created' as keyof typeof a];
    const dateA = new Date(
      createdAObject['_seconds' as keyof typeof createdAObject] * 1000,
    );
    const dateB = new Date(
      createdBObject['_seconds' as keyof typeof createdBObject] * 1000,
    );
    if (!sortDatesInverted) {
      return Number(dateA) - Number(dateB);
    }
    return Number(dateB) - Number(dateA);
  });
