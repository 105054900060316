import { Box, Link, LinkProps } from '@chakra-ui/react';
import { WHATSAPP_URL } from 'helpers/data-helpers/url-helpers';
import { FaWhatsapp } from 'react-icons/fa';
import { colors } from 'styles/colors';

export const WhatsAppLink: React.FC<LinkProps> = (props): JSX.Element => {
  const { ...rest } = props;
  return (
    <Link href={WHATSAPP_URL} {...rest}>
      <Box
        _hover={{ bg: colors.whatsAppGreen[200] }}
        bg={colors.whatsAppGreen[100]}
        borderRadius={10}
        mt={2}
        p={3}
      >
        <FaWhatsapp size={44} />
      </Box>
    </Link>
  );
};

WhatsAppLink.defaultProps = {
  _focus: {
    borderWidth: 0,
    borderColor: 'transparent',
  },
  isExternal: true,
};
