/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
export const alphaSpaceRegex = /^[a-zA-ZÀ-ÿ ]+$/;

export const stringNumRegex = /^[0-9]+$/;

// Regex retrieved from https://www.codeproject.com/Questions/461957/Regular-expression-for-email-validation (modified)
export const emailRegex = /^([0-9a-z]([-.\w]*[0-9a-z])*@([0-9a-z][-\w]*[0-9a-z]\.)+[a-z]{2,5})$/;

export const numberRegex = /^[0-9]*$/;
