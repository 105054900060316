import {
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  StackProps,
  VStack,
} from '@chakra-ui/react';
import { colors } from 'styles/colors';

interface ILoadingStatusModalProps extends StackProps {
  /**
   * Loading status of the operation that determines weather to show content
   * or not.
   */
  loading: boolean;
}

export const LoadingStatusModal: React.FC<ILoadingStatusModalProps> = (
  props,
) => {
  const { children, loading, ...rest } = props;
  return (
    <Modal isOpen={loading} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent bg={colors.blue[700]}>
        <ModalBody>
          <VStack bg="trasnparent" {...rest}>
            <Center flexDir="column">
              <Spinner mb={3} size="xl" />
              <Heading fontSize="xl">{children}</Heading>
            </Center>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

LoadingStatusModal.defaultProps = {
  my: 8,
  px: 12,
  py: 8,
  rounded: 30,
  spacing: 6,
};
