import { InputField } from '../InputField';
import { IInputFieldProps } from '../types.d';

export const DashboardInputField: React.FC<IInputFieldProps> = (
  props,
): JSX.Element => {
  const { ...rest } = props;

  return <InputField w="100%" {...rest} />;
};

DashboardInputField.defaultProps = {
  _focus: { bg: 'white' },
  mx: 2,
};
