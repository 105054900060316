import { Link, LinkProps } from '@chakra-ui/react';
import { Link as ReachLink } from 'react-router-dom';
import { colors } from 'styles/colors';

export interface IStyledLinkProps extends LinkProps {
  /**
   * The route where the router will redirect to.
   */
  to: string;
}

export const StyledLink: React.FC<IStyledLinkProps> = (props): JSX.Element => {
  const { children, to, ...rest } = props;
  return (
    <Link as={ReachLink} to={to} {...rest}>
      {children}
    </Link>
  );
};

StyledLink.defaultProps = {
  px: 3,
  textDecor: 'none',
  _focus: { color: 'white' },
  _hover: { color: colors.blue[200] },
  color: colors.blue[300],
};
