import { StackProps, VStack } from '@chakra-ui/react';
import { useWindowDimensions } from 'components/hooks/useWindowDimensions';
import { colors } from 'styles/colors';

import { Footer } from './components/Footer';
import { Navbar } from './components/Navbar';

export const PageContainer: React.FC<StackProps> = (props): JSX.Element => {
  const { children, ...rest } = props;

  const { height } = useWindowDimensions();

  return (
    <VStack bg={colors.blue[700]} minHeight={height} {...rest}>
      <Navbar bg={colors.blue[100]} />
      {children}
      <Footer />
    </VStack>
  );
};

PageContainer.defaultProps = {
  spacing: 0,
};
