import { HStack, StackProps } from '@chakra-ui/react';
import Family from 'images/home/1.png';
import Individual from 'images/home/2.png';
import Children from 'images/home/3.png';
import Couple from 'images/home/4.png';
import Teenage from 'images/home/5.png';
import Sexuality from 'images/home/6.png';
import { colors } from 'styles/colors';

import { TherapyItem } from './TherapyItem';

export const TherapyTypes: React.FC<StackProps> = (props): JSX.Element => {
  const { ...rest } = props;
  return (
    <HStack {...rest}>
      <TherapyItem
        body="Es el espacio ideal para crear estrategias para que cada miembro de tu
          familia sea escuchado y puedan maximizar su amor."
        src={Family}
        title="FAMILIAR"
        titleColor={colors.blue[900]}
      />
      <TherapyItem
        body="Acudes solito a terapia a trabajar con algunos de nuestros expertos
        los temas de tu vida, con el objetivo de crear herramientas y lograr
        que todo puedas usarlo a tu favor."
        src={Individual}
        title="INDIVIDUAL"
        titleColor={colors.green[200]}
      />
      <TherapyItem
        body="Mediante juegos y diversión nuestros expertos desarrollan habilidades
        en tus hijos mientras te acompañan a ejercer y disfrutar tu
        paternidad/maternidad con amor y límites."
        src={Children}
        title="INFANTIL"
        titleColor={colors.blue[900]}
      />
      <TherapyItem
        body="Acudes con tu pareja para generar recursos para tener la relación que
        siempre has querido tener."
        src={Couple}
        title="PAREJA"
        titleColor={colors.green[200]}
      />
      <TherapyItem
        body="Aprende a disfrutarte... y al prójimo."
        src={Sexuality}
        title="SEXUALIDAD"
        titleColor={colors.blue[900]}
      />
      <TherapyItem
        body="Nuestros expertos apoyan a los adolescentes a desarrollar herramientas
        emocionales para potencializar sus vidas."
        src={Teenage}
        title="ADOLESCENTES"
        titleColor={colors.green[200]}
      />
    </HStack>
  );
};

TherapyTypes.defaultProps = {
  w: '100%',
  bg: 'white',
  alignItems: 'flex-start',
  justifyContent: 'center',
  spacing: 10,
  px: 14,
  pb: 20,
};
