import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  HStack,
  Input,
} from '@chakra-ui/react';
import fp from 'lodash/fp';
import React from 'react';

import { IInputFieldProps } from '../types.d';

export const InputField: React.FC<IInputFieldProps> = (props): JSX.Element => {
  const { label = '', error = '', onChangeText, ...rest } = props;

  return (
    <FormControl h="45px" isInvalid={!fp.isEmpty(error)} mb={4} w="100%">
      <Input
        onChange={({ target: { value: v } }) => {
          onChangeText(v);
        }}
        placeholder={label}
        size="md"
        {...rest}
      />
      {!fp.isEmpty(error) ? (
        <HStack justifyContent="center">
          <FormErrorMessage>
            <FormErrorIcon color="salmon" h={4} ml={3} />
            {error}
          </FormErrorMessage>
        </HStack>
      ) : null}
    </FormControl>
  );
};
