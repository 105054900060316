import { Heading, Spinner, Text, VStack } from '@chakra-ui/react';
import { PageContainer } from 'components/elements';
import { sortObjectArrayByDate } from 'helpers/data-helpers/sorting-helpers';
import fp from 'lodash/fp';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/slices/user';
import { eventsResources } from 'services/resources/events';
import { IEventsEntry } from 'services/resources/events/types.d';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { colors } from 'styles/colors';

import { EventItem } from './components/EventItem';

export const Events: React.FC = (): JSX.Element => {
  const { token, logged } = useSelector(getUser);

  const [loadingEvents, setLoadingEvents] = useState<boolean>(true);
  const [eventsEntries, setEventsEntries] = useState<IEventsEntry[]>([]);

  /**
   * Function for retrieving all the events that are currently stored in the
   * database.
   */
  const retrieveEvents = async (): Promise<void> => {
    try {
      const { data: d } = await eventsResources.get(token);
      const data = d as IEventsEntry[];
      if (!fp.isEmpty(data)) {
        setEventsEntries(data);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Events-retrieveEvents-web',
        user: 'anonymous',
      };
      logsResources.create(newLog, token);
    }
    setLoadingEvents(false);
  };

  const sortedEventEntries = sortObjectArrayByDate(
    eventsEntries,
    true,
  ) as IEventsEntry[];

  useEffect(() => {
    if (logged) retrieveEvents();
  }, []);

  return (
    <PageContainer>
      <VStack bg={colors.blue[700]} spacing={0} w="100%">
        <Text className="suomi-hand-font" fontSize={100} py={10}>
          Eventos
        </Text>
        <VStack
          display={
            !loadingEvents && !fp.isEmpty(eventsEntries) ? 'none' : 'flex'
          }
          pb="60px"
        >
          <VStack bg={colors.blue[100]} px={12} py={8} rounded={30} spacing={6}>
            <Spinner display={loadingEvents ? 'unset' : 'none'} size="xl" />
            <Heading fontSize="xl" pb={loadingEvents ? 0 : 5}>
              {loadingEvents ? 'Cargando Eventos' : 'No se encontraron Eventos'}
            </Heading>
          </VStack>
        </VStack>
        {sortedEventEntries.map((event) => (
          <EventItem
            key={event.title}
            accentColor={event.accentColor}
            cost={event.cost}
            cover={event.cover}
            date={event.date}
            description={event.description}
            id={event.id}
            media={event.media}
            title={event.title}
            videoLinks={event.videoLinks}
          />
        ))}
      </VStack>
    </PageContainer>
  );
};
