import './fonts/Suomi/Suomi-Hand.otf';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { reduxStore } from 'redux/store';

import { App } from './app/App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={reduxStore}>
    <App />
  </Provider>,
);
