import { Heading, Text, VStack } from '@chakra-ui/react';
import { PageContainer } from 'components/elements';
import { colors } from 'styles/colors';

export const Privacy: React.FC = (): JSX.Element => (
  <PageContainer>
    <VStack bg={colors.green[200]} p={20}>
      <Heading as="h1" mb={10}>
        AVISO DE PRIVACIDAD
      </Heading>
      <Text>
        <Text>
          Evolución Terapéutica, S.C., mejor conocido como Evolución
          Terapéutica, con domicilio en calle Lic. Julián Grajales Robles 16,
          colonia Del Valle Centro, ciudad México, municipio o delegación Benito
          Juárez, c.p. 03103, en la entidad de México, país México, es el
          responsable del uso y protección de sus datos personales, y al
          respecto le informamos lo siguiente:
        </Text>
        <Text my={5}>
          <b>¿Para qué fines utilizaremos sus datos personales?</b>
        </Text>
        <Text>
          De manera adicional, utilizaremos su información personal para las
          siguientes finalidades secundarias que <b>no son necesarias</b> para
          el servicio solicitado, pero que nos permiten y facilitan brindarle
          una mejor atención:
        </Text>
        <ul style={{ margin: '20px 0px 20px 20px' }}>
          <li>Gestión de citas del centro terapéutico</li>
          <li>Gestión de tareas del terapeuta al paciente</li>
          <li>Distribución de contenido educativo</li>
          <li>Actividades auxiliares en terapia</li>
          <li>Uso de plataforma digital</li>
          <li>Interacción con usuarios miembros del centro terapéutico</li>
          <li>
            Difusión de contenido relacionado con las labores realizadas en
            centro terapéutico
          </li>
          <li>
            Difusión de contenido relacionado con los cursos y talleres
            ofrecidos en centro terapéutico
          </li>
          <li>Mercadotecnia o publicitaria</li>
        </ul>
        <Text>
          En caso de que no desee que sus datos personales se utilicen para
          estos fines secundarios, escríbanos un correo a{' '}
          <b>development@redmars.tech</b> con la leyenda `No consiento que mis
          datos personales se utilicen para los siguientes fines:`, indicando
          cuáles de estos aplican:
        </Text>
        <ul style={{ margin: '20px 0px 20px 20px' }}>
          <li>Gestión de citas del centro terapéutico</li>
          <li>Gestión de tareas del terapeuta al paciente</li>
          <li>Distribución de contenido educativo</li>
          <li>Actividades auxiliares en terapia</li>
          <li>Uso de plataforma digital</li>
          <li>Interacción con usuarios miembros del centro terapéutico</li>
          <li>
            Difusión de contenido relacionado con las labores realizadas en
            centro terapéutico
          </li>
          <li>
            Difusión de contenido relacionado con los cursos y talleres
            ofrecidos en centro terapéutico
          </li>
          <li>Mercadotecnia o publicitaria</li>
        </ul>
        <Text>
          La negativa para el uso de sus datos personales para estas finalidades
          no podrá ser un motivo para que le neguemos los servicios y productos
          que solicita o contrata con nosotros.
        </Text>
        <Heading as="h4" my={10} size="md">
          ¿Qué datos personales utilizaremos para estos fines?
        </Heading>
        <Text>
          Para llevar a cabo las finalidades descritas en el presente aviso de
          privacidad, utilizaremos los siguientes datos personales:
        </Text>
        <ul style={{ margin: '20px 0px 20px 20px' }}>
          <li>Nombre</li>
          <li>Correo electrónico</li>
          <li>Fotografía</li>
          <li>Pasatiempos</li>
          <li>Aficiones</li>
          <li>Apellido Paterno</li>
          <li>Apellido Materno</li>
        </ul>
        <Heading as="h4" my={10} size="md">
          ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u
          oponerse a su uso?
        </Heading>
        <Text>
          Usted tiene derecho a conocer qué datos personales tenemos de usted,
          para qué los utilizamos y las condiciones del uso que les damos
          (Acceso). Asimismo, es su derecho solicitar la corrección de su
          información personal en caso de que esté desactualizada, sea inexacta
          o incompleta (Rectificación); que la eliminemos de nuestros registros
          o bases de datos cuando considere que la misma no está siendo
          utilizada adecuadamente (Cancelación); así como oponerse al uso de sus
          datos personales para fines específicos (Oposición). Estos derechos se
          conocen como derechos ARCO. Para el ejercicio de cualquiera de los
          derechos ARCO, usted deberá presentar la solicitud respectiva a través
          del siguiente medio:
        </Text>
        <Text my={5}>
          <b>
            <a href="mailto:development@redmars.tech">
              development@redmars.tech
            </a>
          </b>
        </Text>
        <Text>
          Para conocer el procedimiento y requisitos para el ejercicio de los
          derechos ARCO, ponemos a su disposición el siguiente medio:
        </Text>
        <Text my={5}>
          <b>
            <a href="https://www.evoluciont.com.mx">www.evoluciont.com.mx</a>
          </b>
        </Text>
        <Text>
          Los datos de contacto de la persona o departamento de datos
          personales, que está a cargo de dar trámite a las solicitudes de
          derechos ARCO, son los siguientes:
        </Text>
        <ol
          style={{ listStyleType: 'lower-alpha', margin: '20px 0px 20px 20px' }}
        >
          <li>
            Nombre de la persona o departamento de datos personales: Fabio
            Alejandro Valdés Farrugia
          </li>
          <li>
            Domicilio: calle Lic. Julián Grajales Robles 16, colonia Del Valle
            Centro, ciudad México, municipio o delegación Benito Juárez, c.p.
            03103, en la entidad de México, país México
          </li>
          <li>Correo electrónico: info@evoluciont.com.mx</li>
          <li>Número telefónico: 5568409301</li>
        </ol>
        <Heading as="h4" my={10} size="md">
          Usted puede revocar su consentimiento para el uso de sus datos
          personales
        </Heading>
        <Text>
          Usted puede revocar el consentimiento que, en su caso, nos haya
          otorgado para el tratamiento de sus datos personales. Sin embargo, es
          importante que tenga en cuenta que no en todos los casos podremos
          atender su solicitud o concluir el uso de forma inmediata, ya que es
          posible que por alguna obligación legal requiramos seguir tratando sus
          datos personales. Asimismo, usted deberá considerar que para ciertos
          fines, la revocación de su consentimiento implicará que no le podamos
          seguir prestando el servicio que nos solicitó, o la conclusión de su
          relación con nosotros. Para revocar su consentimiento deberá presentar
          su solicitud a través del siguiente medio:
        </Text>
        <Text my={5}>
          <b>
            <a href="mailto:development@redmars.tech">
              development@redmars.tech
            </a>
          </b>
        </Text>
        <Text>
          Para conocer el procedimiento y requisitos para la revocación del
          consentimiento, ponemos a su disposición el siguiente medio:
        </Text>
        <Text my={5}>
          <b>
            <a href="https://www.evoluciont.com.mx">www.evoluciont.com.mx</a>
          </b>
        </Text>
        <Heading as="h4" my={10} size="md">
          ¿Cómo puede limitar el uso o divulgación de su información personal?
        </Heading>
        <Text>
          Con objeto de que usted pueda limitar el uso y divulgación de su
          información personal, le ofrecemos los siguientes medios: A través de
          un correo electrónico a las direcciones{' '}
          <b>
            <a href="mailto:info@evoluciont.com.mx">info@evoluciont.com.mx</a>
          </b>{' '}
          o{' '}
          <b>
            <a href="mailto:info@evoluciont.com.mx">development@redmars.tech</a>
          </b>
          <Heading as="h4" my={10} size="md">
            ¿Cómo puede conocer los cambios en este aviso de privacidad?
          </Heading>
          <Text>
            El presente aviso de privacidad puede sufrir modificaciones, cambios
            o actualizaciones derivadas de nuevos requerimientos legales; de
            nuestras propias necesidades por los productos o servicios que
            ofrecemos; de nuestras prácticas de privacidad; de cambios en
            nuestro modelo de negocio, o por otras causas. Nos comprometemos a
            mantenerlo informado sobre los cambios que pueda sufrir el presente
            aviso de privacidad, a través de: A través de un correo electrónico
            por medio de las direcciones info@evoluciont.com.mx o
            development@redmars.tech así como a través del sitio
            https://www.evoluciont.com.mx/. El procedimiento a través del cual
            se llevarán a cabo las notificaciones sobre cambios o
            actualizaciones al presente aviso de privacidad es el siguiente: Se
            enviará un correo electrónico a aquellos usuarios a quienes les
            competan posibles cambios en el aviso de privacidad, así como
            también se usará una ventana pop-up en la aplicación y/o en nuestro
            sitio web.
          </Text>
          <Text mt={10}>Última actualización: 22/01/2022</Text>
        </Text>
      </Text>
    </VStack>
  </PageContainer>
);
