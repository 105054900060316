import { instance } from 'services';

import { IBlogResponse } from './types.d';

export const blogResources = {
  get: (token: string): Promise<IBlogResponse> =>
    instance.get('/blogAPI', { headers: { authorization: token } }),
  getById: (token: string, entry: string): Promise<IBlogResponse> =>
    instance.get('/blogAPI/byId', {
      headers: { authorization: token },
      params: {
        blogEntry: entry,
      },
    }),
};
