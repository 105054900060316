import {
  Button,
  Center,
  CenterProps,
  Stack,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { colors } from 'styles/colors';

interface IBlogEntryCommentForm extends CenterProps {
  /**
   * Form submission handler.
   */
  onFormSubmit: (payload: string) => void;
}

export const BlogEntryCommentForm: React.FC<IBlogEntryCommentForm> = (
  props,
): JSX.Element => {
  const { onFormSubmit, ...rest } = props;

  const [comment, setComment] = useState<string>('');

  const handleOnInputChange = (commentText: string) => {
    setComment(commentText);
  };

  return (
    <Center {...rest}>
      <VStack w="100%">
        <Textarea
          _focus={{ borderColor: colors.blue[700] }}
          _hover={{ borderColor: colors.blue[700] }}
          borderColor={colors.blue[700]}
          minH="80px"
          onChange={({ target: { value } }) => handleOnInputChange(value)}
          value={comment}
          w="80%"
        />
        <Stack py={1} />
        <Button
          _hover={{ bg: colors.blue[700] }}
          bg={colors.blue[900]}
          color="white"
          onClick={() => {
            onFormSubmit(comment);
            setComment('');
          }}
        >
          Añadir Comentario
        </Button>
        <Stack py={2} />
      </VStack>
    </Center>
  );
};

BlogEntryCommentForm.defaultProps = {
  alignSelf: 'center',
  w: '100%',
};
