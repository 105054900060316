import {
  Center,
  Flex,
  HStack,
  Image,
  Spinner,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { WhatsAppLink } from 'components/elements';
import { useState } from 'react';
import YouTube from 'react-youtube';
import { colors } from 'styles/colors';

export interface ITherapistCardProps extends StackProps {
  /**
   * Source to the picture of each therapist.
   */
  imgSrc: string;
  /**
   * Name of the therapist.
   */
  title: string;
  /**
   * Custom YouTube video identifier.
   */
  videoId: string;
  /**
   * Color variant to be used.
   */
  variant: number;
  /**
   * Custom resize mode to be applied to therapist picture.
   */
  imgResize?: string;
}

export const TherapistCard: React.FC<ITherapistCardProps> = (
  props,
): JSX.Element => {
  const { imgSrc, imgResize, title, videoId, variant, children, ...rest } =
    props;

  const [hasImageLoaded, setHasImageLoaded] = useState<boolean>(false);
  const [imageDisplay, setImageDisplay] = useState<string>('center');

  return (
    <HStack {...rest}>
      <Center
        bg={variant === 1 ? colors.blue[700] : colors.blue[100]}
        h="640px"
        w="20%"
      />
      <VStack
        bg={variant === 1 ? colors.blue[700] : colors.blue[100]}
        h="640px"
        justifyContent="center"
        px={4}
        py={20}
        w="20%"
      >
        <Text
          className="suomi-hand-font"
          color="white"
          fontSize={50}
          pt={8}
          textAlign="center"
        >
          {title}
        </Text>
        <Text fontSize={20} textAlign="center">
          {children}
        </Text>
        <WhatsAppLink pb={10} />
      </VStack>
      {variant === 1 ? (
        <>
          <Flex
            alignItems={imageDisplay}
            bg={colors.green[100]}
            h="640px"
            justifyContent="center"
            w="20%"
          >
            {!hasImageLoaded ? (
              <Spinner color={colors.blue[100]} size="xl" />
            ) : null}

            <Image
              boxSize={imgResize !== '' ? imgResize : '440px'}
              fit="contain"
              onLoad={() => {
                setHasImageLoaded(true);
                setImageDisplay('flex-end');
              }}
              src={imgSrc}
            />
          </Flex>
          <Center
            bg={colors.blue[700]}
            h="640px"
            justifyContent="center"
            pl={4}
            w="20%"
          >
            <YouTube
              opts={{
                width: '270',
                height: '210',
                playerVars: { autoplay: 0 },
              }}
              videoId={videoId}
            />
          </Center>
        </>
      ) : null}

      {variant === 2 ? (
        <>
          <Center
            bg={colors.blue[100]}
            h="640px"
            justifyContent="center"
            pr={4}
            w="20%"
          >
            <YouTube
              opts={{
                width: '270',
                height: '210',
                playerVars: { autoplay: 0 },
              }}
              videoId={videoId}
            />
          </Center>
          <Flex
            alignItems={imageDisplay}
            bg={colors.green[100]}
            h="640px"
            justifyContent="center"
            w="20%"
          >
            {!hasImageLoaded ? (
              <Spinner color={colors.blue[700]} size="xl" />
            ) : null}

            <Image
              boxSize={imgResize !== '' ? imgResize : '440px'}
              fit="contain"
              onLoad={() => {
                setHasImageLoaded(true);
                setImageDisplay('flex-end');
              }}
              src={imgSrc}
            />
          </Flex>
        </>
      ) : null}
      <Center
        bg={variant === 1 ? colors.blue[700] : colors.blue[100]}
        h="640px"
        w="20%"
      />
    </HStack>
  );
};

TherapistCard.defaultProps = {
  w: '100%',
  h: '100%',
  spacing: 0,
};
