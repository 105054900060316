import { initializeApp } from 'firebase/app';
import {
  initializeAuth,
  signInAnonymously,
  signInWithEmailAndPassword,
  UserCredential,
} from 'firebase/auth';

const V2_ACCOUNT = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
};

export const authResources = {
  anonymouslyLogIn: (): Promise<UserCredential> =>
    signInAnonymously(initializeAuth(initializeApp(V2_ACCOUNT))),
  logInWithEmailAndPass: (
    email: string,
    pass: string,
  ): Promise<UserCredential> =>
    signInWithEmailAndPassword(
      initializeAuth(initializeApp(V2_ACCOUNT)),
      email,
      pass,
    ),
};
