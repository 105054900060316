import { Box, Flex, FlexProps, Image } from '@chakra-ui/react';
import Slide2Image from 'images/home/slide-2.png';
import { colors } from 'styles/colors';

export const Slide2: React.FC<FlexProps> = (props): JSX.Element => {
  const { ...rest } = props;

  return (
    <Flex {...rest}>
      <Box bg="white" h="200px" w="100%" />
      <Box bg={colors.green[100]} h="700px" w="100%" />
      <Image
        boxSize="750px"
        objectFit="contain"
        position="absolute"
        src={Slide2Image}
      />
    </Flex>
  );
};

Slide2.defaultProps = {
  w: '100%',
  h: '100%',
  direction: 'column',
};
