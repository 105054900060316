import { Button, Divider, StackProps, Text, VStack } from '@chakra-ui/react';
import { LogInForm } from 'components/forms/LogInForm/LogInForm';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/slices/user';
import { IBlogComment } from 'services/resources/blog-entry-comments/types.d';
import { colors } from 'styles/colors';

import { CommentItem } from './CommentItem';
import { NewCommentContainer } from './NewCommentContainer';

interface ICommentsContainerProps extends StackProps {
  /**
   * Actual comments to be displayed for the corresponding blog entry.
   */
  blogEntryComments: IBlogComment[];
  /**
   * Handler function for appending a new comment.
   */
  onNewCommentSubmit: (newComment: string) => void;
}

export const CommentsContainer: React.FC<ICommentsContainerProps> = (
  props,
): JSX.Element => {
  const { blogEntryComments, onNewCommentSubmit, ...rest } = props;
  const { logged, anonymous, firstName } = useSelector(getUser);

  const [logInFormVisible, setLogInFormVisible] = useState<boolean>(false);

  return (
    <VStack {...rest}>
      <LogInForm
        isOpen={logInFormVisible}
        onClose={() => setLogInFormVisible(false)}
      />
      {logged && anonymous ? (
        <VStack pb={5}>
          <Text pb={3}>
            Debes iniciar sesión con tu cuenta de <b>TerAppia</b> para poder
            comentar.
          </Text>
          <Button
            _hover={{ bg: colors.green[200] }}
            bg={colors.green[100]}
            onClick={() => setLogInFormVisible(true)}
          >
            Iniciar Sesión de TerAppia
          </Button>
        </VStack>
      ) : (
        <NewCommentContainer
          onAccountChange={() => setLogInFormVisible(true)}
          onFormSubmit={(p) => onNewCommentSubmit(p)}
          user={firstName}
        />
      )}
      <Divider bg={colors.blue[900]} h={1} w="100%" />
      {blogEntryComments.length > 0 ? (
        blogEntryComments.map((comment, index) => (
          <CommentItem
            key={comment.id}
            blogComment={comment}
            showDivider={index !== blogEntryComments.length - 1}
          />
        ))
      ) : (
        <Text pt={5}>Aún no se han añadido comentarios.</Text>
      )}
    </VStack>
  );
};

CommentsContainer.defaultProps = {
  py: 5,
  color: 'black',
  bg: 'white',
  w: '90%',
  borderRadius: 10,
  spacing: 0,
};
