import { HStack, StackProps, Text, VStack } from '@chakra-ui/react';
import { BlogEntryCommentForm } from 'components/forms';

interface INewCommentContainerProps extends StackProps {
  /**
   * User fist name to be shown in the comments' section.
   */
  user: string;
  /**
   * Handler for form submission.
   */
  onFormSubmit: (content: string) => void;
  /**
   * Handler for switching TerAppia account.
   */
  onAccountChange: () => void;
}

export const NewCommentContainer: React.FC<INewCommentContainerProps> = (
  props,
): JSX.Element => {
  const { onAccountChange, user, onFormSubmit, ...rest } = props;
  return (
    <VStack {...rest}>
      <VStack pb={2} spacing={0} w="100%">
        <HStack spacing={1}>
          <Text>Estás comentando como</Text>
          <Text fontWeight="bold">{`${user}.`}</Text>
          <Text>Si quieres cambiar de usuario, presiona</Text>
        </HStack>
        <Text
          _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
          fontWeight="bold"
          onClick={onAccountChange}
        >
          aquí.
        </Text>
      </VStack>
      <BlogEntryCommentForm onFormSubmit={onFormSubmit} />
    </VStack>
  );
};

NewCommentContainer.defaultProps = {
  w: '100%',
  alignItems: 'center',
};
