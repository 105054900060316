import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import { PageContainer } from 'components/elements';
import Adriana from 'images/staff/adriana.png';
import Alessia from 'images/staff/alessia.png';
import Amilcar from 'images/staff/amilcar.png';
import ETYT from 'images/staff/et-yt.png';
import Fabio from 'images/staff/fabio.png';
import Gabriela from 'images/staff/gabriela.png';
import Lorena from 'images/staff/lorena.png';
import Therapists from 'images/staff/therapists.png';
import WeET from 'images/staff/we-are-et.png';
import YouTube from 'react-youtube';
import { colors } from 'styles/colors';

import { TherapistCard } from './TherapistCard/TherapistCard';

export const Staff: React.FC = () => (
  <PageContainer>
    <VStack spacing={0} w="100%">
      <VStack bg={colors.blue[700]} w="100%">
        <Text className="suomi-hand-font" fontSize={120}>
          Conócenos
        </Text>
        <Image boxSize="80%" fit="contain" src={Therapists} />
      </VStack>
      <VStack bg="white" justifyContent="center" px={10} w="100%">
        <Image boxSize="75%" fit="contain" py={10} src={WeET} />
        <HStack pb={10}>
          <YouTube
            opts={{ height: '305', width: '500', playerVars: { autoplay: 0 } }}
            videoId="ISM4FLLxohE"
          />
          <Image boxSize="45%" fit="contain" src={ETYT} />
        </HStack>
      </VStack>
      <TherapistCard
        imgSrc={Alessia}
        title="Alessia Di Bari"
        variant={1}
        videoId="d9VWrlgUmnI"
      >
        <b>Sexóloga, terapeuta de pareja y consteladora familiar,</b> con ganas
        de escucharte y aprender de ti y de tu historia; en constante proceso de
        reestructuración. Mi carácter apasionado y risueño, te ayudará a tomarte
        la vida menos en serio. Aprendiz del disfrute y el amor propio.
        <b> ¡Lista para apoyarte a encontrar tu pasión!</b>
      </TherapistCard>
      <TherapistCard
        imgSrc={Amilcar}
        title="Amilcar Valdés"
        variant={2}
        videoId="rUAIaEamwDo"
      >
        <b>La mezcla “imperfecta” entre profesionalismo,</b> diversión y
        compromiso. Comprometido a crear un mundo mejor, doy todo por ti leal a
        tu crecimiento.
      </TherapistCard>
      <TherapistCard
        imgSrc={Fabio}
        title="Fabio Valdés"
        variant={1}
        videoId="NmuidIU5rX0"
      >
        Apasionado de la psicología, los videojuegos y las constelaciones
        familiares. Experto en miedo.
        <b>La vida es inspiración.</b>
      </TherapistCard>
      <TherapistCard
        imgSrc={Adriana}
        title="Adriana Carrillo"
        variant={2}
        videoId="PZIiVCQhPj4"
      >
        <b>Psicóloga, Terapeuta Familiar y Consteladora Familiar.</b> Cuento con
        experiencia en temas de manejo familiar, maternidad y paternidad. Mi
        carácter experimentador te apoyará a desarrollar valentía y diversión en
        tu vida para amarte cada día un poquito más.
      </TherapistCard>
      <TherapistCard
        imgSrc={Lorena}
        title="Lorena Niño de Ribera"
        variant={1}
        videoId="wMHgKblYiR4"
      >
        Psicóloga y consteladora familiar con muchas ganas de compartir mi vida
        para hacer mejor la tuya. La más joven del equipo. Cuento con gran
        experiencia en temas de manejo de ansiedad y adolescentes. Mi visión
        sobre la autoexigencia y autocompasión te acompañará a desarrollar esas
        herramientas.
      </TherapistCard>
      <TherapistCard
        imgSrc={Gabriela}
        title="Gabriela Ávila"
        variant={2}
        videoId="aZeSRcGZK2U"
      >
        Psicóloga y Consteladora Familiar. Tengo amplia experiencia tratando
        adultos y a través de la dedicación, paciencia y constancia y una
        continua renovación, te acompaño a encontrar tu bienestar emocional así
        como tu pasión de vida.
      </TherapistCard>
    </VStack>
  </PageContainer>
);
