import 'styles/styles.css';

import {
  Center,
  Image,
  SimpleGrid,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import ModelImage from 'images/home/model.png';
import { colors } from 'styles/colors';

import { FeatureItem } from './FeatureItem';

export const Model: React.FC<StackProps> = (props): JSX.Element => {
  const { ...rest } = props;
  return (
    <VStack {...rest}>
      <Center bg="white" py={14} w="100%">
        <VStack>
          <Center w="75%">
            <Text
              className="suomi-hand-font"
              color={colors.blue[700]}
              fontSize={76}
            >
              Con nuestro
            </Text>
            <Text
              color={colors.green[100]}
              fontSize={46}
              fontWeight="bold"
              px={4}
            >
              Modelo Aprendes
            </Text>
            <Text color={colors.blue[700]} fontSize={46}>
              a validar tus
            </Text>
          </Center>
          <Center style={{ marginTop: -26 }} w="75%">
            <Text color={colors.blue[700]} fontSize={46} textAlign="center">
              emociones, dejando las etiquetas a un lado y entendiéndolas según
              el contexto; te acompañamos a reconocerlas y a usarlas como un
              recurso.
            </Text>
          </Center>
        </VStack>
      </Center>
      <SimpleGrid columns={2} w="100%">
        <Image h="100%" src={ModelImage} style={{ width: '100%' }} />
        <SimpleGrid columns={2}>
          <FeatureItem
            bg={colors.green[100]}
            body={`
                Si al imaginar 'proceso emocional' piensas en un diván y tú
                hablando mientras un viejito toma notas de ti, probablemente te
                sorprendas cuando vengas con nosotros. Si lo que buscas es en un
                ambiente seguro, aprender a ver cosas de ti y tomar herramientas
                para sentirte tranquilo y ser amoroso contigo y la gente que te
                rodea, ¡bienvenido!, has llegado a casa.
                `}
            title="Acompañamiento Emocional"
          />
          <FeatureItem
            bg={colors.blue[100]}
            body="En diversos lugares del mundo se ha observado que uno de los
            problemas más comunes a los cuales se enfrentan las empresas es la
            rotación de personal, pareciera que éstas asumen que no es
            evitable y que es un costo que tienen que absorber. Lo que han
            hecho algunas personas para evitar esto es mantener al personal en
            un estado anímico, activo, creativo y relajado, donde se sienten
            muy a gusto con su empresa y por lo tanto generan lealtad a ella."
            title="Empresarial"
          />
          <FeatureItem
            bg={colors.blue[700]}
            body="Siempre acorde con nuestra visión, nuestras conferencias son
          cercanas, juguetonas y creativas… todo esto sin dejar de ser
          profesionales."
            title="Conferencias"
          />
          <FeatureItem
            bg={colors.green[100]}
            body="Jugamos, cuestionamos, cuidamos y empoderamos a los participantes."
            title="Cursos y Talleres"
          />
        </SimpleGrid>
      </SimpleGrid>
    </VStack>
  );
};

Model.defaultProps = {
  w: '100%',
  spacing: 0,
};
