import { Center, StackProps, Text, VStack } from '@chakra-ui/react';
import { colors } from 'styles/colors';

export const Experts: React.FC<StackProps> = (props): JSX.Element => {
  const { ...rest } = props;
  return (
    <VStack {...rest}>
      <Center w="80%">
        <Text
          className="suomi-hand-font"
          color={colors.green[100]}
          fontSize={76}
          pr={4}
        >
          Somos expertos
        </Text>
        <Text color={colors.blue[700]} fontSize={32}>
          en el manejo de la ansiedad y de la mano, te
        </Text>
      </Center>
      <Text color={colors.blue[700]} fontSize={32} px={80} textAlign="left">
        llevamos a <b>vivir tus emociones</b> y con esto, encontrar la paz que
        tanto buscas. <b>Disfrutar y alcanzar tus sueños, es posible</b> ❤.
      </Text>
    </VStack>
  );
};

Experts.defaultProps = {
  w: '100%',
  bg: 'white',
  pt: 12,
  pb: 24,
};
