/* eslint-disable no-underscore-dangle */
import { VStack } from '@chakra-ui/react';
import {
  BlogEntryItem,
  LoadingStatus,
  LoadingStatusModal,
  PageContainer,
} from 'components/elements';
import fp from 'lodash/fp';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUser } from 'redux/slices/user';
import { blogResources } from 'services/resources/blog';
import { IBlogEntry } from 'services/resources/blog/types.d';
import { blogEntryCommentsResources } from 'services/resources/blog-entry-comments';
import {
  IBlogComment,
  IBlogCommentPayload,
} from 'services/resources/blog-entry-comments/types.d';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { colors } from 'styles/colors';
import { ILoadingData } from 'types.d';

import { CommentsContainer } from './components/CommentsContainer';

export const BlogEntry: React.FC = () => {
  const { token, logged, firstName, fatherName, motherName, id, email } =
    useSelector(getUser);
  const { entryId } = useParams();

  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });

  const [loadingModalData, setLoadingModalData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [blogEntry, setBlogEntry] = useState<IBlogEntry>({} as IBlogEntry);
  const [entryComments, setEntryComments] = useState<IBlogComment[]>([]);

  const retrieveEntryComments = async () => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Cargando Comentarios...',
    });
    try {
      const { data: d } = await blogEntryCommentsResources.getByBlogEntryId(
        token,
        entryId as string,
      );
      const data = d as IBlogComment[];
      const sortedData = data.sort(
        (a, b) => b.created._seconds - a.created._seconds,
      );
      if (!fp.isEmpty(sortedData)) setEntryComments(sortedData);
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'BlogEntry-retrieveEntryComments-web',
        user: 'anonymous',
      };
      logsResources.create(newLog, token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function for retrieving all the events that are currently stored in the
   * database.
   */
  const retrieveBlogEntry = async (): Promise<void> => {
    setLoadingModalData({
      loading: true,
      loadingMessage: 'Cargando Entrada de Blog...',
    });
    try {
      const { data: d } = await blogResources.getById(token, entryId as string);
      const data = d as IBlogEntry;
      if (!fp.isEmpty(data)) {
        setBlogEntry(data);
        retrieveEntryComments();
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'BlogEntry-retrieveBlogEntry-web',
        user: 'anonymous',
      };
      logsResources.create(newLog, token);
    }
    setLoadingModalData({ ...loadingData, loading: false });
  };

  const handleOnNewCommentSubmit = async (comment: string): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Añadiendo Comentario...',
    });
    try {
      const newCommentPayload: IBlogCommentPayload = {
        comment,
        commentAuthor: `${firstName} ${fatherName} ${motherName}`,
        commentAuthorId: id,
        blogEntry: entryId as string,
      };
      await blogEntryCommentsResources.create(newCommentPayload, token);
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'BlogEntry-handleOnNewCommentSubmit-web',
        user: email,
      };
      logsResources.create(newLog, token);
    }
    setLoadingData({ ...loadingData, loading: false });
    retrieveBlogEntry();
  };

  useEffect(() => {
    if (logged) retrieveBlogEntry();
  }, [logged]);

  return (
    <PageContainer>
      <VStack bg={colors.blue[700]} py={10} spacing={0} w="100%">
        <LoadingStatus loading={loadingData.loading}>
          {loadingData.loadingMessage}
        </LoadingStatus>
        <LoadingStatusModal loading={loadingModalData.loading}>
          {loadingModalData.loadingMessage}
        </LoadingStatusModal>
        {!fp.isEmpty(blogEntry) ? <BlogEntryItem entry={blogEntry} /> : null}
        {!fp.isEmpty(blogEntry) ? (
          <CommentsContainer
            blogEntryComments={entryComments}
            onNewCommentSubmit={(p) => handleOnNewCommentSubmit(p)}
          />
        ) : null}
      </VStack>
    </PageContainer>
  );
};
