import {
  Button,
  Center,
  Image,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import SamuraiImage from 'images/home/samurai.jpeg';
import { colors } from 'styles/colors';

export const Samurai: React.FC<StackProps> = (props): JSX.Element => {
  const { ...rest } = props;
  return (
    <VStack {...rest}>
      <Center bg={colors.blue[100]} flexDirection="column" pb={8} w="100%">
        <VStack bg={colors.aqua[700]} pt={10} w="50%">
          <Image src={SamuraiImage} />
          <Text fontSize={58} pb={12} pt={2} textAlign="center">
            Regístrate al evento
          </Text>
        </VStack>
      </Center>
      <Center bg={colors.blue[700]} py={14} w="100%">
        <VStack>
          <Text fontSize={40} textAlign="center">
            Regístrate
          </Text>
          <Button
            _focus={{}}
            _hover={{ bg: colors.green[200], color: 'white' }}
            bg={colors.green[100]}
            borderRadius={1}
            color="black"
            fontSize={30}
            h={58}
          >
            Aquí
          </Button>
        </VStack>
      </Center>
    </VStack>
  );
};

Samurai.defaultProps = {
  w: '100%',
  justifyContent: 'center',
  spacing: 0,
};
