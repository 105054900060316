import {
  Button,
  GridProps,
  Image,
  Link,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import { PODCAST_URL, YOUTUBE_URL } from 'helpers/data-helpers/url-helpers';
import PodcastImage from 'images/home/podcast.png';
import PodcastImageText from 'images/home/podcast-text.png';
import YoutubeImage from 'images/home/youtube.png';
import { colors } from 'styles/colors';

export const Media: React.FC<GridProps> = (props): JSX.Element => {
  const { ...rest } = props;

  return (
    <SimpleGrid columns={2} {...rest}>
      <VStack bg={colors.blue[900]} py={10}>
        <Image src={PodcastImage} />
        <Text fontSize={62} fontWeight="bold">
          PODCAST
        </Text>
        <Text fontSize={40}>Eso te pasa</Text>
        <SimpleGrid alignItems="center" columns={2} style={{ marginTop: -36 }}>
          <Text color={colors.green[100]} fontSize={40} textAlign="center">
            por...
          </Text>
          <Image boxSize="150px" fit="contain" src={PodcastImageText} />
        </SimpleGrid>
        <Link href={PODCAST_URL} isExternal>
          <Button
            _focus={{}}
            _hover={{ bg: colors.green[100], color: 'black' }}
            bg="transparent"
            borderColor={colors.green[100]}
            borderRadius={30}
            borderWidth={1}
            color={colors.green[100]}
            fontSize={20}
            h={50}
          >
            Escucha nuestro Podcast
          </Button>
        </Link>
      </VStack>
      <VStack bg={colors.blue[100]}>
        <Image boxSize="lg" fit="contain" src={YoutubeImage} />
        <Link href={YOUTUBE_URL} isExternal>
          <Button
            _focus={{}}
            _hover={{ bg: colors.green[200], color: 'white' }}
            bg="transparent"
            borderColor={colors.green[100]}
            borderRadius={30}
            borderWidth={1}
            color="white"
            fontSize={20}
            h={50}
          >
            Ve nuestro canal de YouTube
          </Button>
        </Link>
      </VStack>
    </SimpleGrid>
  );
};

Media.defaultProps = {
  w: '100%',
};
