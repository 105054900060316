import { Image, Text, VStack } from '@chakra-ui/react';
import { PageContainer } from 'components/elements';
import Family from 'images/therapies/1.png';
import Individual from 'images/therapies/2.png';
import Children from 'images/therapies/3.png';
import Couple from 'images/therapies/4.png';
import Teenage from 'images/therapies/5.png';
import Sexuality from 'images/therapies/6.png';
import TherapiesImage from 'images/therapies/therapies.png';
import { colors } from 'styles/colors';

import { Therapy } from './components/Therapy';

export const Therapies: React.FC = (): JSX.Element => (
  <PageContainer>
    <VStack spacing={0} w="100%">
      <VStack bg={colors.blue[700]} py={20} w="100%">
        <Text className="suomi-hand-font" color="white" fontSize={120}>
          Terapias
        </Text>
        <Image boxSize="80%" src={TherapiesImage} />
      </VStack>
      <Therapy
        bg={colors.blue[100]}
        body="Es el espacio ideal para crear estrategias para que cada miembro de tu familia sea escuchado y puedan maximizar su amor."
        src={Family}
        title="Terapia Familiar"
      />
      <Therapy
        bg={colors.blue[700]}
        body="Acudes solito a terapia a trabajar con algunos de nuestros expertos los temas de tu vida, con el objetivo de crear herramientas y lograr que todo puedas usarlo a tu favor."
        imageDir="left"
        src={Individual}
        title="Terapia Individual"
      />
      <Therapy
        bg={colors.blue[100]}
        body="Mediante juegos y diversión nuestros expertos desarrollan habilidades en tus hijos mientras te acompañan a ejercer y disfrutar tu paternidad/maternidad con amor y lím"
        src={Children}
        title="Terapia Infantil"
      />
      <Therapy
        bg={colors.blue[700]}
        body="Acudes con tu pareja para generar recursos para tener la relación que siempre has querido tener."
        imageDir="left"
        src={Couple}
        title="Terapia de Pareja"
      />
      <Therapy
        bg={colors.blue[100]}
        body="Aprende a disfrutarte... y al prójimo."
        src={Sexuality}
        title="Sexualidad"
      />
      <Therapy
        bg={colors.blue[700]}
        body="Nuestros expertos apoyan a los adolescentes a desarrollar herramientas emocionales para potencializar sus vidas."
        imageDir="left"
        src={Teenage}
        title="Terapia de Adolescentes"
      />
    </VStack>
  </PageContainer>
);
