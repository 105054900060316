import { authResources } from '../auth';

/**
 * Function that anonymously authenticates the current session user.
 */
export const logInAnonymously = async (): Promise<string> => {
  let token = '';
  try {
    const { user } = await authResources.anonymouslyLogIn();
    token = await user.getIdToken();
    return token;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Unexpected error on anlog');
  }
  return token;
};
