import { Divider, HStack, StackProps, Text, VStack } from '@chakra-ui/react';
import { IBlogComment } from 'services/resources/blog-entry-comments/types.d';
import { colors } from 'styles/colors';

interface ICommentItemProps extends StackProps {
  /**
   * Comment from which will retrieve the desired info to be displayed.
   */
  blogComment: IBlogComment;
  /**
   * Flag that indicates weather to show divider or not.
   */
  showDivider?: boolean;
}

export const CommentItem: React.FC<ICommentItemProps> = (
  props,
): JSX.Element => {
  const { blogComment, showDivider = true, ...rest } = props;
  return (
    <VStack {...rest}>
      <HStack pt={5} px={3} spacing={1} w="100%">
        <Text fontWeight="bold">{blogComment.commentAuthor}</Text>
        <Text>commentó:</Text>
      </HStack>
      <Text alignSelf="flex-start" px={3}>
        {blogComment.comment}
      </Text>
      <HStack pb={2} />
      {showDivider ? <Divider bg={colors.blue[900]} h={1} w="100%" /> : null}
    </VStack>
  );
};

CommentItem.defaultProps = {
  w: '100%',
};
