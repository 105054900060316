import { Image, StackProps, Text, VStack } from '@chakra-ui/react';

export interface ITherapyItemProps extends StackProps {
  src: string;
  title: string;
  titleColor: string;
  body: string;
}

export const TherapyItem: React.FC<ITherapyItemProps> = (
  props,
): JSX.Element => {
  const { title, src, titleColor, body, ...rest } = props;
  return (
    <VStack {...rest}>
      <Image borderRadius="full" boxSize="150px" src={src} />
      <Text color={titleColor} fontWeight="bold">
        {title}
      </Text>
      <Text color="black" textAlign="center">
        {body}
      </Text>
    </VStack>
  );
};

TherapyItem.defaultProps = {
  py: 4,
  maxW: '150px',
};
