/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
import {
  Box,
  Flex,
  HStack,
  Link,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import * as dompurify from 'dompurify';
import {
  RiFacebookFill,
  RiTelegramFill,
  RiTwitterFill,
  RiWhatsappFill,
} from 'react-icons/ri';
import { Link as RichLink } from 'react-router-dom';
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { IBlogEntry } from 'services/resources/blog/types.d';

interface IBlogEntryItemProps extends StackProps {
  /**
   * Actual blog entry from which we will retrieve the desired data.
   */
  entry: IBlogEntry;
  /**
   * Flag to indicate weather to show partial content or the full blog entry.
   */
  preview?: boolean;
}

export const BlogEntryItem: React.FC<IBlogEntryItemProps> = (
  props,
): JSX.Element => {
  const { entry, preview = false, ...rest } = props;

  const { content, title, created, updated, author, id: entryId } = entry;

  const SHARE_URL = window.location.href;

  return (
    <VStack {...rest}>
      <Flex justifyContent="center" w="100%">
        <Text
          className="suomi-hand-font"
          fontSize={48}
          fontWeight="bold"
          pb={5}
          textAlign="center"
        >
          {title}
        </Text>
      </Flex>
      <HStack pb={2}>
        <Text>
          {`Creado: ${format(
            new Date(created._seconds * 1000),
            'EEE dd MMM yyyy',
            {
              locale: es,
            },
          )}, editado: ${format(
            new Date(updated._seconds * 1000),
            'EEE dd MMM yyyy',
            {
              locale: es,
            },
          )} por: `}
        </Text>
        <Text fontSize={18} fontWeight="bold" textAlign="center">
          {author}
        </Text>
      </HStack>
      <Box bg="white" borderRadius={10} color="black" p={3} w="90%">
        {preview ? (
          <Text
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(content) }}
            noOfLines={3}
          />
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(content) }}
          />
        )}
      </Box>
      {!preview ? (
        <HStack pb={2} pt={4}>
          <FacebookShareButton url={SHARE_URL}>
            <RiFacebookFill size={36} />
          </FacebookShareButton>
          <WhatsappShareButton url={SHARE_URL}>
            <RiWhatsappFill size={36} />
          </WhatsappShareButton>
          <TelegramShareButton url={SHARE_URL}>
            <RiTelegramFill size={36} />
          </TelegramShareButton>
          <TwitterShareButton url={SHARE_URL}>
            <RiTwitterFill size={36} />
          </TwitterShareButton>
        </HStack>
      ) : null}
      {preview ? (
        <Link
          as={RichLink}
          fontSize={18}
          fontWeight="bold"
          py={4}
          to={`/blogEntry/${entryId}`}
        >
          Leer más...
        </Link>
      ) : null}
    </VStack>
  );
};

BlogEntryItem.defaultProps = {
  py: 4,
  w: '100%',
};
