import {
  HStack,
  Image,
  Link,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  LoadingStatusModal,
  PageContainer,
  WhatsAppLink,
} from 'components/elements';
import { ContactRequestForm } from 'components/forms';
import {
  FACEBOOK_URL,
  LOCATION_URL,
  SPOTIFY_URL,
  YOUTUBE_URL,
} from 'helpers/data-helpers/url-helpers';
import ET from 'images/about-us/evolucion-terapeutica.png';
import Facebook from 'images/about-us/facebook.png';
import Hands from 'images/about-us/hands.png';
import Spotify from 'images/about-us/spotify.png';
import Staff from 'images/about-us/staff.png';
import YouTube from 'images/about-us/youtube.png';
import { useState } from 'react';
import { MdLocationPin } from 'react-icons/md';
import { RiMailFill, RiPhoneFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/slices/user';
import { contactRequestsResources } from 'services/resources/contact-requests';
import { IContactRequestPayload } from 'services/resources/contact-requests/types.d';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { colors } from 'styles/colors';
import { ILoadingData } from 'types.d';

export const ContactUs: React.FC = (): JSX.Element => {
  const { token } = useSelector(getUser);

  const [loadingModalData, setLoadingModalData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });

  /**
   * Function that will handle the contact request submission.
   * @param payload
   */
  const handleCreateContactRequest = async (
    payload: IContactRequestPayload,
  ): Promise<void> => {
    setLoadingModalData({
      loading: true,
      loadingMessage: 'Enviando Solicitud...',
    });
    try {
      await contactRequestsResources.create(payload, token);
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'ContactUs-createContactRequest-web',
        user: 'anonymous',
      };
      logsResources.create(newLog, token);
    }
    setLoadingModalData({ ...loadingModalData, loading: false });
  };

  return (
    <PageContainer>
      <LoadingStatusModal loading={loadingModalData.loading}>
        {loadingModalData.loadingMessage}
      </LoadingStatusModal>
      <SimpleGrid columns={2} w="100%">
        <Image boxSize="100%" fit="cover" src={Hands} />
        <SimpleGrid columns={2} w="100%">
          <VStack bg={colors.green[100]} py={4} textAlign="center">
            <Text className="suomi-hand-font" fontSize={56}>
              Contáctanos
            </Text>
            <Text fontSize={20} px={4}>
              Contáctenos sobre cualquier cosa relacionada con nuestra empresa o
              nuestros servicios. Haremos todo lo posible por darle respuesta a
              la brevedad.
            </Text>
            <WhatsAppLink />
          </VStack>
          <VStack bg="white" pb={10} textAlign="center">
            <Image src={ET} />
            <Text>Evolución Terapéutica, S.C.</Text>
            <Text color={colors.green[100]} fontSize={20} fontWeight="bold">
              <Link href={LOCATION_URL} isExternal>
                Ver en Google Maps
              </Link>
            </Text>
          </VStack>
          <VStack bg="white" textAlign="center">
            <Link href={FACEBOOK_URL} isExternal>
              <Image src={Facebook} />
            </Link>
            <Link href={SPOTIFY_URL} isExternal>
              <Image src={Spotify} />
            </Link>
            <Link href={YOUTUBE_URL} isExternal>
              <Image src={YouTube} />
            </Link>
          </VStack>
          <Image src={Staff} w="110%" />
        </SimpleGrid>
      </SimpleGrid>
      <HStack bg="white" justifyContent="space-evenly" w="100%">
        <ContactRequestForm onSubmit={handleCreateContactRequest} />
        <VStack color="gray">
          <Text fontSize={20}>Evolución Terapéutica, S.C.</Text>
          <HStack>
            <MdLocationPin size={20} />
            <Text fontSize={20}>
              Grajales Robles 16 03103 Benito Juárez, DIF México
            </Text>
          </HStack>
          <HStack>
            <RiPhoneFill size={20} />
            <Text fontSize={20}>
              (+52)556840 9301, (+52)5548790009 (WhatsApp)
            </Text>
          </HStack>
          <HStack>
            <RiMailFill size={20} />
            <Text fontSize={20}>info@evoluciont.com.mx</Text>
          </HStack>
        </VStack>
      </HStack>
    </PageContainer>
  );
};
