import { Heading, Spinner, Text, VStack } from '@chakra-ui/react';
import { BlogEntryItem, PageContainer } from 'components/elements';
import { sortObjectArrayByCreated } from 'helpers/data-helpers/sorting-helpers';
import fp from 'lodash/fp';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/slices/user';
import { blogResources } from 'services/resources/blog';
import { IBlogEntry } from 'services/resources/blog/types.d';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { colors } from 'styles/colors';

export const Blog: React.FC = (): JSX.Element => {
  const { token, logged } = useSelector(getUser);

  const [loading, setLoading] = useState<boolean>(true);
  const [blogEntries, setBlogEntries] = useState<IBlogEntry[]>([]);

  /**
   * Function for retrieving all the events that are currently stored in the
   * database.
   */
  const retrieveBlogEntries = async (): Promise<void> => {
    try {
      const { data: d } = await blogResources.get(token);
      const data = d as IBlogEntry[];
      if (!fp.isEmpty(data)) {
        setBlogEntries(data);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Blog-retrieveBlogEntries-web',
        user: 'anonymous',
      };
      logsResources.create(newLog, token);
    }
    setLoading(false);
  };

  const sortedBlogEntries = sortObjectArrayByCreated(
    blogEntries,
    true,
  ) as IBlogEntry[];

  useEffect(() => {
    if (logged) retrieveBlogEntries();
  }, [logged]);

  return (
    <PageContainer>
      <VStack bg={colors.blue[700]} spacing={0} w="100%">
        <Text className="suomi-hand-font" fontSize={100} py={10}>
          Blog
        </Text>
        <VStack
          display={!loading && !fp.isEmpty(sortedBlogEntries) ? 'none' : 'flex'}
          pb="60px"
        >
          <VStack bg={colors.blue[100]} px={12} py={8} rounded={30} spacing={6}>
            <Spinner display={loading ? 'unset' : 'none'} size="xl" />
            <Heading fontSize="xl" pb={loading ? 0 : 5}>
              {loading
                ? 'Cargando Blog...'
                : 'No se encontraron entradas de Blog.'}
            </Heading>
          </VStack>
        </VStack>
        {sortedBlogEntries.map((entry, index) => (
          <BlogEntryItem
            key={entry.id}
            bg={index % 2 === 0 ? colors.green[200] : colors.blue[900]}
            entry={entry}
            preview
          />
        ))}
      </VStack>
    </PageContainer>
  );
};
