import { StackProps, Text, VStack } from '@chakra-ui/react';

export interface IFeatureItemProps extends StackProps {
  title: string;
  body: string;
}

export const FeatureItem: React.FC<IFeatureItemProps> = (
  props,
): JSX.Element => {
  const { title, body, ...rest } = props;
  return (
    <VStack textAlign="center" {...rest}>
      <Text className="suomi-hand-font" fontSize={56}>
        {title}
      </Text>
      <Text fontSize={18} px={6}>
        {body}
      </Text>
    </VStack>
  );
};
