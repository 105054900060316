import { instance } from 'services/instance';

import { IUserResponse } from './types.d';

export const userResources = {
  getByMail: (email: string, token: string): Promise<IUserResponse> =>
    instance.get('/usersAPI', {
      headers: { authorization: token },
      params: { email },
    }),
  getById: (id: string, token: string): Promise<IUserResponse> =>
    instance.get('/usersAPI', {
      headers: { authorization: token },
      params: { id },
    }),
};
