import {
  alphaSpaceRegex,
  emailRegex,
  stringNumRegex,
} from 'helpers/data-helpers/regex-helpers';
import { IContactRequestPayload } from 'services/resources/contact-requests/types.d';
import * as Yup from 'yup';

export const contactRequestFormCopies = {
  firstName: 'Nombre',
  fatherName: 'Apellido(s)',
  email: 'Correo Electrónico',
  phone: 'Número con WhatsApp',
  contactReason: 'Razón de Contacto',
};

export const INITIAL_VALUES: IContactRequestPayload = {
  firstName: '',
  fatherName: '',
  email: '',
  phone: '',
  contactReason: '',
  requestRead: false,
  readBy: '',
};

export const ContactRequestFormValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(
      alphaSpaceRegex,
      'Tu nombre no puede contener caracteres especiales ni números.',
    )
    .required('Este campo es requerido.'),
  fatherName: Yup.string()
    .matches(
      alphaSpaceRegex,
      'Tu(s) apellido(s) no puede(n) contener caracteres especiales ni números.',
    )
    .required('Este campo es requerido.'),
  email: Yup.string()
    .matches(emailRegex, 'Debes ingresar un correo válido.')
    .required('Este campo es requerido.'),
  phone: Yup.string()
    .matches(stringNumRegex, 'Debes ingresar un teléfono válido.')
    .min(10, 'Tu número debe tener 10 dígitos.')
    .max(10, 'Tu número debe tener 10 dígitos.')
    .required('Este campo es requerido.'),
  contactReason: Yup.string().required('Este campo es requerido.'),
});
