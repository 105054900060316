import './carousel.css';

import { Flex, FlexProps } from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';

import { Slide1 } from './Slide1';
import { Slide2 } from './Slide2';

export const Slider: React.FC<FlexProps> = (props): JSX.Element => {
  const { ...rest } = props;

  return (
    <Flex {...rest}>
      <Carousel
        autoPlay
        className="carousel"
        infiniteLoop
        interval={5000}
        showArrows
        showStatus={false}
        showThumbs={false}
      >
        <Slide1 />
        <Slide2 />
      </Carousel>
    </Flex>
  );
};

Slider.defaultProps = {
  h: '900px',
  bg: 'green',
  justifyContent: 'center',
  w: '100%',
};
