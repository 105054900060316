import {
  Button,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { LoadingStatus } from 'components/elements';
import { TERAPPIA_LOGO_URL } from 'helpers/data-helpers/url-helpers';
import fp from 'lodash/fp';
import React, { useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setUserData } from 'redux/slices/user';
import { IUserState } from 'redux/types.d';
import { authResources } from 'services/resources/auth';
import { IBaseAuthentication } from 'services/resources/auth/types.d';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { userResources } from 'services/resources/users';
import { IUser } from 'services/resources/users/types.d';
import { colors } from 'styles/colors';
import { ILoadingData } from 'types.d';

export interface ILoginFormProps {
  /**
   * Flag for deciding to show the modal or not.
   */
  isOpen: boolean;
  /**
   * Handler function for the modal closing.
   */
  onClose: () => void;
}

export const LogInForm: React.FC<ILoginFormProps> = (props): JSX.Element => {
  const { isOpen, onClose } = props;
  const { token } = useSelector(getUser);

  const dispatch = useDispatch();

  const [baseAuthentication, setBaseAuthentication] =
    useState<IBaseAuthentication>({} as IBaseAuthentication);
  const [isInputShowed, setIsInputShowed] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [errorMessage, setErrorMessage] = useState<string>('');

  const toggleIsInputShowed = () => {
    setIsInputShowed(!isInputShowed);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputChange = (e: any) => {
    setBaseAuthentication({
      ...baseAuthentication,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnSubmit = async (): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Iniciando Sesión...',
    });
    try {
      const { user } = await authResources.logInWithEmailAndPass(
        baseAuthentication.email,
        baseAuthentication.password,
      );
      const loggedUserToken = await user.getIdToken();
      const { data } = await userResources.getByMail(
        baseAuthentication.email,
        loggedUserToken,
      );
      const d = data as IUser;
      const loggedUser: IUserState = {
        ...d,
        logged: true,
        token: loggedUserToken,
        anonymous: false,
      };
      dispatch(setUserData(loggedUser as IUserState));
    } catch (e) {
      setErrorMessage(
        'Hubo un error al iniciar sesión. Verifica que tengas una cuenta existente y verificada de TerAppia y que los datos ingresados sean correctos.',
      );
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'LogInForm-handleOnSubmit-web',
        user: 'anonymous',
      };
      logsResources.create(newLog, token);
    }
    setBaseAuthentication({} as IBaseAuthentication);
    setLoadingData({
      ...loadingData,
      loading: false,
    });
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setBaseAuthentication({} as IBaseAuthentication);
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent bg={colors.blue[700]}>
        <ModalCloseButton />
        <ModalBody>
          <VStack pt={12} spacing={4}>
            <Image
              alignSelf="center"
              alt="terappia-logo"
              h="120px"
              src={TERAPPIA_LOGO_URL}
            />
            <Input
              _placeholder={{ color: 'gray' }}
              bg="white"
              color="black"
              name="email"
              onChange={handleInputChange}
              placeholder="Correo electrónico"
              value={baseAuthentication.email}
            />
            <InputGroup>
              <Input
                _placeholder={{ color: 'gray' }}
                bg="white"
                color="black"
                name="password"
                onChange={handleInputChange}
                placeholder="Contraseña"
                type={isInputShowed ? 'text' : 'password'}
                value={baseAuthentication.password}
              />
              <InputRightElement mr={1} w="50px">
                <IconButton
                  aria-label="show/hide"
                  bg="transparent"
                  icon={
                    isInputShowed ? (
                      <AiFillEye size={20} />
                    ) : (
                      <AiFillEyeInvisible size={20} />
                    )
                  }
                  onClick={() => toggleIsInputShowed()}
                  size="sm"
                />
              </InputRightElement>
            </InputGroup>
            <Stack py={2} />
            {!loadingData.loading ? (
              <Button
                bg={colors.blue[100]}
                onClick={(e) => {
                  e.preventDefault();
                  handleOnSubmit();
                }}
                w="150px"
              >
                Iniciar Sesión
              </Button>
            ) : (
              <LoadingStatus bg="transparent" loading={loadingData.loading}>
                {loadingData.loadingMessage}
              </LoadingStatus>
            )}
            {!fp.isEmpty(errorMessage) ? (
              <Text color="red">{errorMessage}</Text>
            ) : null}
            <Stack py={2} />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
