import {
  Button,
  HStack,
  Image,
  Link,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { WHATSAPP_URL } from 'helpers/data-helpers/url-helpers';
import { colors } from 'styles/colors';

export interface ITherapyProps extends StackProps {
  title: string;
  body: string;
  src: string;
  imageDir?: string;
}

export const Therapy: React.FC<ITherapyProps> = (props) => {
  const { imageDir = 'right', title, body, src, ...rest } = props;
  return (
    <HStack {...rest}>
      {imageDir === 'left' ? (
        <Image borderRadius="full" boxSize="sm" fit="contain" src={src} />
      ) : null}
      <VStack>
        <Text
          className="suomi-hand-font"
          color="white"
          fontSize={120}
          lineHeight={1.1}
          textAlign="center"
        >
          {title}
        </Text>
        <Text pb={10}>{body}</Text>
        <Link
          _focus={{ borderWidth: 0, borderColor: 'transparent' }}
          href={WHATSAPP_URL}
          isExternal
        >
          <Button
            _focus={{}}
            _hover={{ bg: colors.green[200], color: 'white' }}
            bg={colors.green[100]}
            borderRadius={1}
            color="black"
            fontSize={20}
            h={50}
          >
            Genera tu cita
          </Button>
        </Link>
      </VStack>
      {imageDir === 'right' ? (
        <Image borderRadius="full" boxSize="sm" fit="contain" src={src} />
      ) : null}
    </HStack>
  );
};

Therapy.defaultProps = {
  w: '100%',
  justifyContent: 'center',
  px: 250,
  py: 74,
  spacing: 20,
};
