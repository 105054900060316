import { Center, Heading, Spinner, StackProps, VStack } from '@chakra-ui/react';
import { colors } from 'styles/colors';

interface ILoadingStatusProps extends StackProps {
  /**
   * Loading status of the operation that determines weather to show content
   * or not.
   */
  loading: boolean;
}

export const LoadingStatus: React.FC<ILoadingStatusProps> = (props) => {
  const { children, loading, ...rest } = props;
  return (
    <VStack
      bg={colors.blue[100]}
      display={loading ? undefined : 'none'}
      {...rest}
    >
      <Center flexDir="column">
        <Spinner mb={3} size="xl" />
        <Heading fontSize="xl">{children}</Heading>
      </Center>
    </VStack>
  );
};

LoadingStatus.defaultProps = {
  my: 8,
  px: 12,
  py: 8,
  rounded: 30,
  spacing: 6,
};
