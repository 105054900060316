import { instance } from 'services';

import { IBlogCommentPayload, IBlogResponse } from './types.d';

export const blogEntryCommentsResources = {
  create: (payload: IBlogCommentPayload, token: string): Promise<void> =>
    instance.post(
      '/blogCommentsAPI',
      { blogComment: payload },
      { headers: { authorization: token } },
    ),
  getByBlogEntryId: (token: string, entry: string): Promise<IBlogResponse> =>
    instance.get('/blogCommentsAPI', {
      headers: { authorization: token },
      params: {
        blogEntry: entry,
      },
    }),
};
