export const colors = {
  blue: {
    100: '#1aa6c5',
    200: '#c6e9ef',
    300: '#8dd3e2',
    700: '#2c3b8d',
    900: '#161740',
  },
  green: {
    100: '#8dc349',
    200: '#7aad39',
  },
  gray: {
    100: '#f8f9fa',
  },
  aqua: {
    700: '#0d5362',
  },
  whatsAppGreen: {
    100: '#44c654',
    200: '#328e3e',
  },
};
