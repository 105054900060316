export const API_URL =
  'https://627498313d2b5100742e0d2f.mockapi.io/api/v1/todos';

export const USER_API_URL = 'https://randomuser.me/api';

export const WHATSAPP_URL = 'https://wa.me/525548790009';

export const PODCAST_URL = 'https://anchor.fm/evoluciont';

export const FACEBOOK_URL = 'https://www.facebook.com/EvoTerapeutica/';

export const LOCATION_URL = 'https://goo.gl/maps/cGSVxDMeMuGMmttD6';

export const YOUTUBE_URL =
  'https://www.youtube.com/c/Evoluci%C3%B3nTerap%C3%A9utica';

export const TWITTER_URL =
  'https://twitter.com/EvoTera?s=20&t=JIluUWmRWpZuJGWSLWiTjA';

export const LINKEDIN_URL =
  'https://www.linkedin.com/company/evolucion-terapeutica/';

export const SPOTIFY_URL =
  'https://open.spotify.com/show/0H4jSJ9v5QlfOZuseEKvEp';

export const TERAPPIA_LOGO_URL =
  'https://firebasestorage.googleapis.com/v0/b/terappia-v2.appspot.com/o/images%2FTerAppia%2Flogo.png?alt=media&token=55a993d3-ef47-4b31-b2f3-133e24e4a0aa';

export const TERAPPIA_URL = 'https://terappia.evoluciont.com.mx/';
